<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}保健产品</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="保健产品名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="成分" prop="ingredient">
				<el-input v-model.trim="ruleForm.ingredient" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="性状" prop="drugProperty">
				<el-input v-model.trim="ruleForm.drugProperty" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="功能主治" prop="attention">
				<el-input v-model.trim="ruleForm.attention" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="适宜人群" prop="notation" class="tagsClass">
				<el-tag :key="tag" v-for="tag in ruleForm.notation" :closable="!isShowDetail" :disable-transitions="false" @close="handleClose(tag)">
					{{ tag }}
				</el-tag>
				<el-input
					class="input-new-tag"
					v-if="inputVisible"
					v-model="inputValue"
					ref="saveTagInput"
					size="small"
					@keyup.enter.native="handleInputConfirm"
					@blur="handleInputConfirm"
				>
				</el-input>
				<el-button v-if="!inputVisible && ruleForm.notation.length < 4 && !isShowDetail" class="button-new-tag" size="small" @click="showInput"
					>+ 添加标签</el-button
				>
			</el-form-item>
			<el-form-item label="用法用量" prop="specification">
				<el-input v-model.trim="ruleForm.specification" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="不良反应" prop="adverseReactions">
				<el-input v-model.trim="ruleForm.adverseReactions" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="禁忌" prop="taboo">
				<el-input v-model.trim="ruleForm.taboo" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="注意事项" prop="benefits">
				<el-input v-model.trim="ruleForm.benefits" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="规格" prop="standards" class="w50 mr16">
				<el-input v-model.trim="ruleForm.standards" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="贮藏" prop="storage" class="w50 mr0">
				<el-input v-model.trim="ruleForm.storage" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="包装" prop="packing" class="w50 mr16">
				<el-input v-model.trim="ruleForm.packing" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="有效期" prop="validityDate" class="w50 mr0">
				<el-input v-model.trim="ruleForm.validityDate" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="执行标准" prop="executiveStandards">
				<el-input v-model.trim="ruleForm.executiveStandards" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="生产企业" prop="manufacturingEnterprise" class="w50 mr16">
				<el-input v-model.trim="ruleForm.manufacturingEnterprise" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="批准文号" prop="approvalNumber" class="w50 mr0">
				<el-input v-model.trim="ruleForm.approvalNumber" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="生产地址" prop="productionAddress" class="w50 mr16">
				<el-input v-model.trim="ruleForm.productionAddress" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="邮政编码" prop="zipCode" class="w50 mr0">
				<el-input v-model.trim="ruleForm.zipCode" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="电话号码" prop="phoneNumber" class="w50 mr16">
				<el-input v-model.trim="ruleForm.phoneNumber" maxlength="11" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="注册地址" prop="companyAddress" class="w50 mr0">
				<el-input v-model.trim="ruleForm.companyAddress" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="网址" prop="website">
				<el-input v-model.trim="ruleForm.website" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="上传封面图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="MEDICINE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
			<el-form-item label="上传详情图片" prop="detailsImg">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.detailsImg"
					modulePath="MEDICINE"
					@uploadImg="uploadDetailsImg"
					operator="zhangl"
					:maxCount="3"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifyPhone, verifyPostalCode, verifyUrl } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		ImageAndVideo: () => import('@/components/ImageAndVideo/index.vue'),
	},

	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		var checkZipCode = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入邮编'));
			} else if (!verifyPostalCode(value)) {
				callback(new Error('请输入正确的邮编'));
			} else {
				callback();
			}
		};
		var checkWebSite = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入网址'));
			} else if (value != '-' && !verifyUrl(value)) {
				callback(new Error('请输入正确的网址'));
			} else {
				callback();
			}
		};

		return {
			createdVisible: false,
			ruleForm: {
				name: '', //药品名称
				drugProperty: '', //性状
				ingredient: '', //成分
				attention: '', //功能主治
				standards: '', //规格
				specification: '', //用法用量
				validityDate: '', //有效期
				images: [], //图片
				detailsImg: [],
				notation: [], //适宜人群
				benefits: '', //注意事项
				adverseReactions: '', //不良反应
				taboo: '', //禁忌
				storage: '', //贮藏
				packing: '', //包装
				executiveStandards: '', //执行标准
				manufacturingEnterprise: '', //生产企业
				approvalNumber: '', //批准文号
				productionAddress: '', //生产地址
				zipCode: '', //邮政编码
				phoneNumber: '', //电话号码
				companyAddress: '', //注册地址
				website: '-', //网址
			},
			deviceTypeList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				drugProperty: [{ required: true, message: '请输入', trigger: 'blur' }],
				ingredient: [{ required: true, message: '请输入', trigger: 'blur' }],
				attention: [{ required: true, message: '请输入', trigger: 'blur' }],
				notation: [{ required: true, message: '请输入', trigger: 'blur' }],
				standards: [{ required: true, message: '请输入', trigger: 'blur' }],
				specification: [{ required: true, message: '请输入', trigger: 'blur' }],
				validityDate: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传', trigger: 'blur' }],
				detailsImg: [{ required: true, message: '请上传', trigger: 'blur' }],
				benefits: [{ required: true, message: '请输入', trigger: 'blur' }],
				adverseReactions: [{ required: true, message: '请输入', trigger: 'blur' }],
				taboo: [{ required: true, message: '请输入', trigger: 'blur' }],
				storage: [{ required: true, message: '请输入', trigger: 'blur' }],
				packing: [{ required: true, message: '请输入', trigger: 'blur' }],
				executiveStandards: [{ required: true, message: '请输入', trigger: 'blur' }],
				manufacturingEnterprise: [{ required: true, message: '请输入', trigger: 'blur' }],
				approvalNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
				productionAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
				zipCode: [{ required: true, validator: checkZipCode, trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				companyAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
				website: [{ required: true, validator: checkWebSite, trigger: 'blur' }],
			},
			saveLoading: false,
			link: '',
			attachmentInfo: [],
			inputVisible: false,
			inputValue: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.link = data.link;
			this.getAttachment();
			if (data.row) {
				this.getDetail(this.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		handleClose(tag) {
			this.ruleForm.notation.splice(this.ruleForm.notation.indexOf(tag), 1);
			this.$forceUpdate();
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick((_) => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.ruleForm.notation.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.notation = JSON.parse(JSON.stringify(this.ruleForm.appropriateCrowd?.split(','))) || [];
			this.ruleForm.images =
				data.attachmentMap?.PHOTO?.map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				}) || [];
			this.ruleForm.detailsImg =
				data.attachmentMap?.DETAILPHOTO?.map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				}) || [];
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'MEDICINE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let imgs =
						this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'COVER').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'COVER').id,
								mimeType: item.title.split('.')[1],
							};
						}) || [];
					let detailsImg =
						this.ruleForm.detailsImg.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}) || [];
					let params = {
						...this.ruleForm,
						medicineTypeCode: 'H',
						attachments: [...imgs, ...detailsImg],
						appropriateCrowd: this.ruleForm.notation.join(','),
					};
					console.log(params, 'params');
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
		uploadDetailsImg(dataImg) {
			this.ruleForm.detailsImg = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.tagsClass {
		.el-tag {
			margin-right: 16px;
		}
		.el-input {
			width: 88px;
		}
		.el-button--default {
			margin-left: 0;
			padding: 9px 16px;
		}
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>